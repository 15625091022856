import { Link } from '@remix-run/react'
import {
    Stack,
    Text,
    Divider,
    useColorModeValue
} from '@chakra-ui/react'

import theme from '../../../Config/theme'

import PrimaryButton from '../../Elements/PrimaryButton'

const LimitReached = () => {
    const borderColor = useColorModeValue(theme.borderColor.borderColorLight, theme.borderColor.borderColorDark)

    return (
        <>
            <Divider borderColor={borderColor} />
            <Stack
                p={3}
                bgColor={theme.default.defaultTransparentBackground}
                borderRadius={'lg'}
                textAlign={'center'}
                spacing={3}
            >
                <Text>You've reached your limit for this month.</Text>
                <Link to={'/pricing'}>
                    <PrimaryButton label={'Upgrade Now'} />
                </Link>
            </Stack>
        </>
    )
}

export default LimitReached