import {
    Button
} from '@chakra-ui/react'

import theme from '../../Config/theme'

const TextButton = ({ label, onClick }) => {
    return (
        <Button
            _active={{}}
            _focus={{}}
            _hover={{}}
            size={'lg'}
            bgColor={'transparent'}
            // color={'black'}
            textDecoration={'underline'}
            textDecorationColor={theme.default.defaultColor}
            borderRadius={'full'}
            onClick={onClick}
        >
            {label}
        </Button>
    )
}

export default TextButton