import {
    Stack,
    CircularProgress
} from '@chakra-ui/react'

import theme from '../../../Config/theme'

const LoadingSpinner = () => {
    return (
        <Stack align={'center'}>
            <CircularProgress color={theme.default.defaultColor} isIndeterminate />
        </Stack>
    )
}

export default LoadingSpinner