import { NavLink } from '@remix-run/react'
import {
    Box,
    Flex,
    Button,
    useDisclosure,
    Stack,
    useColorMode,
    Heading,
    useColorModeValue,
    Text,
    Divider,
    StackDivider,
    useMediaQuery,
} from '@chakra-ui/react'

import theme from '../../Config/theme'

const NavigationBarItem = ({ to, label, badgeLabel }) => {
    return (
        <NavLink to={to}>
            {({ isActive, isPending }) => (
                <Button
                    _active={{}}
                    _focus={{}}
                    _hover={{
                        color: theme.default.defaultColor
                    }}
                    fontWeight={'700'}
                    size={'2xl'}
                    bgColor={'transparent'}
                    borderRadius={'full'}
                    px={0}
                >
                    <Stack direction={'row'} align={'center'}>
                        <Text color={isActive && theme.default.defaultColor}>{label}</Text>
                        {badgeLabel &&
                            <Text
                                bgColor={theme.default.defaultColor}
                                px={2}
                                py={1}
                                borderRadius={'full'}
                                color={'black'}
                            >{badgeLabel}</Text>
                        }
                    </Stack>
                </Button>
            )}
        </NavLink>
    )
}

export default NavigationBarItem