import theme from '../../../Config/theme'

import { Button } from '@chakra-ui/react'

const TextButton = ({ children, size, onClick, ...rest }) => {
    return (
        <Button
            _active={{}}
            _focus={{}}
            _hover={{
                color: theme.default.defaultColor
            }}
            size={size || 'md'}
            bgColor={'transparent'}
            onClick={onClick}
            textDecoration={'underline'}
            textDecorationColor={theme.default.defaultColor}
            {...rest}
        >
            {children}
        </Button>
    )
}

export default TextButton