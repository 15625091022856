import { Link } from '@remix-run/react'

import {
    Container,
    Stack,
    StackDivider,
    Divider,
    Text,
    Heading,
    useColorModeValue,
    useMediaQuery
} from '@chakra-ui/react'

import theme from '../../Config/theme'

const Footer = () => {
    const borderColor = useColorModeValue(theme.borderColor.borderColorLight, theme.borderColor.borderColorDark)
    const isDesktop = useMediaQuery("(min-width: 768px)")

    return (
        <Container
            maxW={'3xl'}
        >
            <Stack
                direction={{ base: 'column', md: 'row' }}
                justify={'center'}
                spacing={5}
                textAlign={'center'}
                divider={isDesktop[0] && <StackDivider borderColor={borderColor} />}
            >
                <Text fontWeight={'700'}>© DaddysTeach 2023</Text>
                <Link to='/faq'><Text fontWeight={'700'}>FAQ</Text></Link>
                <Link to='/terms'><Text fontWeight={'700'}>Terms</Text></Link>
                <Link to='/privacy'><Text fontWeight={'700'}>Privacy</Text></Link>
                <Link to='/disclaimer'><Text fontWeight={'700'}>Disclaimer</Text></Link>
                <Link to='/contact'><Text fontWeight={'700'}>Contact Us</Text></Link>
            </Stack>
            <Stack pt={'5%'}>
                <Divider borderColor={borderColor} />
            </Stack>
            <Stack justify={'center'} align={'center'} p={'5%'}>
                <Link to='/'>
                    <Heading fontSize='4xl' color={theme.default.defaultColor}>daddysteach</Heading>
                </Link>
            </Stack>
        </Container>
    )
}

export default Footer