import theme from '../../Config/theme'
import config from '../../Config/config'

import { Link } from '@remix-run/react'
import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    Text,
    CloseButton,
    Box,
    useColorModeValue,
    Flex,
    Stack,
    StackDivider
} from '@chakra-ui/react'

import NavigationBarItem from './NavigationBarItem'

import { PrimaryButton, SecondaryButton, TextButton } from '../Elements/Buttons'

import useIsAuthenticated from '../../CustomHooks/useIsAuthenticated'

import MyAccountMobile from './MyAccount/MyAccountMobile'

const MobileSidebar = ({ isOpen, onClose }) => {
    const isAuthenticated = useIsAuthenticated()

    const borderColor = useColorModeValue(theme.borderColor.borderColorLight, theme.borderColor.borderColorDark)

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement={'left'}
            size={'full'}
        >
            <DrawerOverlay />
            <DrawerContent>
                <Box
                    transition={'3s ease'}
                    bg={useColorModeValue('gray.50', 'gray.900')}
                    borderRight={'1px'}
                    borderRightColor={useColorModeValue('gray.200', 'gray.700')}
                    w={'full'}
                    pos={'fixed'}
                    h={'full'}
                >
                    <Flex h={'20'} alignItems={'center'} mx={'8'} justifyContent={'space-between'}>
                        <Text
                            fontSize={'2xl'}
                            fontWeight={700}
                            color={theme.default.defaultColor}
                            userSelect={'none'}
                        >
                            daddysteach
                        </Text>
                        <CloseButton borderRadius={'full'} onClick={onClose} />
                    </Flex>
                    <Stack
                        direction={'column'}
                        mx={8}
                        spacing={3}
                        divider={<StackDivider borderColor={borderColor} />}
                    >
                        {isAuthenticated &&
                            <MyAccountMobile />
                        }

                        <Stack
                            direction={'column'}
                            alignItems={'start'}
                            spacing={3}
                        >
                            <NavigationBarItem to={'/'} label={'Homework Q&A'} />
                            <NavigationBarItem to={'/scholarships'} label={'Scholarships'} />
                            <NavigationBarItem to={'/pricing'} label={'Plans'} />
                        </Stack>

                        {!isAuthenticated &&
                            <Stack direction={'column'} alignItems={'center'}>
                                <Link to={config.AUTH_PAGE_URL + '/'}>
                                    <TextButton p={0} w={'full'}>Login</TextButton>
                                </Link>

                                <Link to={config.AUTH_PAGE_URL + '/signup'} style={{ width: '100%' }}>
                                    <PrimaryButton>Create Account</PrimaryButton>
                                </Link>
                            </Stack>
                        }
                    </Stack>

                </Box>
            </DrawerContent>
        </Drawer>
    )
}

export default MobileSidebar