import { logout } from '../Api/Auth'

const handleLogout = async (reload) => {
    await logout()

    if (reload) {
        window.location.replace('/')
    }
}

export { handleLogout }