import { useState, useEffect } from 'react'
import { Link } from '@remix-run/react'
import {
    Flex,
    Button,
    Stack,
    useColorModeValue,
    Text
} from '@chakra-ui/react'

import CustomHighlight from '../Elements/CustomHighlight'

import { useLocalStorage } from '../../CustomHooks/useLocalStorage'

import theme from '../../Config/theme'

const CookiesMessage = () => {
    const borderColor = useColorModeValue(theme.borderColor.borderColorLight, theme.borderColor.borderColorDark)

    const [shouldRender, setShouldRender] = useState(false)
    const [cookiesAccepted, setCookiesAccepted] = useLocalStorage('dt_cookies_accepted', false)

    useEffect(() => {
        setShouldRender(true)
    }, [])

    return (
        <>
            {shouldRender && cookiesAccepted === false &&
                <Flex
                    h={'auto'}
                    textAlign={'center'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    borderBottom={'1px'}
                    borderColor={borderColor}
                    background={`url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%230D9494' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");`}
                >
                    <Stack direction={{ base: 'column', md: 'row' }} align={'center'} py={2}>
                        <Text fontWeight={'700'}>This website uses cookies to ensure you get the best experience on our website. <Link to='/privacy' style={{ color: theme.default.defaultColor }}><CustomHighlight query={['Learn more.']}>Learn more.</CustomHighlight></Link></Text>
                        <Button
                            _active={{}}
                            _focus={{}}
                            _hover={{}}
                            bgColor={theme.default.defaultColor}
                            borderRadius={'full'}
                            color={'white'}
                            onClick={() => setCookiesAccepted(true)}

                        >
                            Got it!</Button>
                    </Stack>
                </Flex>
            }
        </>
    )
}

export default CookiesMessage