import { Link } from '@remix-run/react'

import config from '../../Config/config'

// import PrimaryButton from '../Elements/PrimaryButton'
// import TextButton from '../Elements/TextButton'
import { PrimaryButton, SecondaryButton, TextButton } from '../Elements/Buttons'

const AuthButtons = () => {
    return (
        <>
            <Link to={config.AUTH_PAGE_URL + '/'}>
                <TextButton>Login</TextButton>
            </Link>

            <Link to={config.AUTH_PAGE_URL + '/signup'}>
                <PrimaryButton>Create Account</PrimaryButton>
            </Link>
        </>
    )
}

export default AuthButtons