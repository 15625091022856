import { create } from 'zustand'

const useGlobalAlertDialog = create((set) => ({
    alertData: {
        isOpen: false,
        title: '',
        message: '',
        primaryButton: {
            label: null,
            handler: null,
        },
        secondaryButton: {
            label: null,
            handler: null,
        }
    },
    displayGlobalAlertDialog: (data) => set((state) => ({
        alertData: {
            isOpen: true,
            title: data?.title,
            message: data?.message,
            primaryButton: {
                label: data?.primaryButton?.label,
                handler: data?.primaryButton?.handler,
            },
            secondaryButton: {
                label: data?.secondaryButton?.label,
                handler: data?.secondaryButton?.handler,
            }
        }
    })),
    closeGlobalAlertDialog: () => set((state) => ({
        alertData: {
            isOpen: false,
            title: '',
            message: '',
            primaryButton: {
                label: null,
                handler: null,
            },
            secondaryButton: {
                label: null,
                handler: null,
            }
        }
    }))
}))

export default useGlobalAlertDialog