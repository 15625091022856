const requestProgressBarColor = (questionsSent, maxQuestions) => {
    if (questionsSent / maxQuestions >= 0.75) {
        return 'red'
    }

    if (questionsSent / maxQuestions > 0.5) {
        return 'yellow'
    }

    if (questionsSent / maxQuestions <= 0.5) {
        return 'green'
    }
}

export default requestProgressBarColor