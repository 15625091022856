import { useState, useEffect } from 'react'

export const useLocalStorage = (key, fallbackValue) => {
    const [value, setValue] = useState(fallbackValue);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const stored = localStorage.getItem(key);
            setValue(stored ? JSON.parse(stored) : fallbackValue);
        }
    }, [fallbackValue, key]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }, [key, value]);

    return [value, setValue];
}