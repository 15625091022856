import { useState } from 'react'
import {
    Button,
    Stack,
    useColorModeValue,
    Text,
    Progress,
    Divider,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    HStack,
    VStack,
    Box
} from '@chakra-ui/react'
import moment from 'moment'

import theme from '../../../Config/theme'
import config from '../../../Config/config'

import { FiChevronDown } from 'react-icons/fi'
import { FaUser } from 'react-icons/fa'

import { createBillingPortalLink } from '../../../Api/Stripe'
import { getAccountDetails } from '../../../Api/MyAccount'

import LoadingSpinner from './LoadingSpinner'
import LimitReached from './LimitReached'

import requestProgressBarColor from '../../../Utils/requestProgressBarColor'

import useGlobalAlertDialog from '../../../Stores/useGlobalAlertDialog'
import useMatchesData from '../../../CustomHooks/useMatchesData'

import { handleLogout } from '../../../Helpers/Auth'

const MyAccountMobile = () => {
    const cookieObject = useMatchesData('root').cookieObject

    const displayGlobalAlertDialog = useGlobalAlertDialog((state) => state.displayGlobalAlertDialog)
    const closeGlobalAlertDialog = useGlobalAlertDialog((state) => state.closeGlobalAlertDialog)

    const { isOpen, onOpen, onClose } = useDisclosure()

    const bgColor = useColorModeValue('gray.50', 'gray.900')
    const progressBgColor = useColorModeValue('gray.100', 'gray.800')
    const borderColor = useColorModeValue(theme.borderColor.borderColorLight, theme.borderColor.borderColorDark)
    const secondaryTextColor = useColorModeValue('gray.600', 'gray.400')

    const [loading, setLoading] = useState(true)
    const [accountDetails, setAccountDetails] = useState({
        username: '',
        questionsSent: '',
        maxQuestions: '',
        limitReached: false,
        limitResetsOn: '',
        planId: null
    })

    const subscriptionErrorTypes = ["subscriptionPastDue", "subscriptionUnpaid", "subscriptionCanceled", "subscriptionIncomplete", "subscriptionPaused"]

    const fetchAccountDetails = async () => {
        const response = await getAccountDetails()

        if (response.data.error) {
            if (subscriptionErrorTypes.includes(response.data.errorType)) {
                displayGlobalAlertDialog({
                    title: response.data.errorType,
                    message: response.data.message,
                    primaryButton: {
                        label: 'Manage Subscription',
                        handler: () => handleManageSubscription()
                    },
                    secondaryButton: {
                        label: 'Close',
                        handler: () => {
                            onClose()
                            closeGlobalAlertDialog()
                        }
                    }
                })
            } else {
                displayGlobalAlertDialog({
                    title: response.data.errorType,
                    message: response.data.message,
                    primaryButton: {
                        label: 'Sign in',
                        handler: () => window.location.replace(config.AUTH_PAGE_URL + '/')
                    },
                    secondaryButton: {
                        label: 'Close',
                        handler: () => window.location.replace('/')
                    }
                })
                handleLogout(false)
            }
        }

        if (!response.data.error) {
            setAccountDetails({
                username: response.data.data.username,
                questionsSent: response.data.data.ask.questionsSent,
                maxQuestions: response.data.data.ask.maxQuestions,
                limitReached: response.data.data.ask.limitReached,
                limitResetsOn: response.data.data.ask.limitResetsOn,
                planId: response.data.data.planId
            })
        }

        setLoading(false)
    }

    const handleManageSubscription = async () => {
        const response = await createBillingPortalLink()

        if (response.data.error) {
            handleLogout(false)
            window.location.replace(config.AUTH_PAGE_URL)
        } else {
            window.location.replace(response.data.url)
        }
    }

    const handleOpenModal = () => {
        onOpen()
        fetchAccountDetails()
    }

    return (
        <>
            <HStack justify={'space-between'} onClick={handleOpenModal}>
                <HStack>
                    <Button
                        _hover={{}}
                        _active={{}}
                        _focus={{}}
                        size={'md'}
                        style={{ boxShadow: 'none' }}
                        bgColor={theme.default.defaultColor}
                        rounded={'full'}
                        p={0}
                        color={'white'}
                    >
                        {cookieObject.dt_username ?
                            cookieObject.dt_username[0] :
                            <FaUser />
                        }
                    </Button>
                    <VStack
                        alignItems={'flex-start'}
                        spacing={'1px'}
                        ml='2'
                    >
                        <Text fontSize={'sm'}>{cookieObject.dt_username || 'My Account'}</Text>
                    </VStack>
                </HStack>
                <Box>
                    <FiChevronDown />
                </Box>
            </HStack>

            <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
                <ModalOverlay />
                <ModalContent bgColor={bgColor}>
                    <ModalHeader>My Account</ModalHeader>
                    <ModalCloseButton _active={{}} _focus={{}} _hover={{}} />
                    <ModalBody>
                        {loading ?
                            <LoadingSpinner />
                            :
                            <Stack spacing={2} >
                                <Text fontSize={'lg'} pt={1}>Hello, {accountDetails.username}!</Text>
                                <Divider borderColor={borderColor} />

                                <Text fontSize={'lg'}>Your questions this month</Text>
                                <Text fontSize={'md'} color={'gray.500'}>
                                    {accountDetails.questionsSent} out of {accountDetails.maxQuestions === -1 ? '∞ (unlimited)' : accountDetails.maxQuestions}
                                </Text>

                                {accountDetails.planId !== 3 &&
                                    <>
                                        <Progress
                                            colorScheme={requestProgressBarColor(accountDetails.questionsSent, accountDetails.maxQuestions)}
                                            height={'32px'}
                                            borderRadius={'full'}
                                            value={accountDetails.questionsSent}
                                            min={0}
                                            max={accountDetails.maxQuestions}
                                            bgColor={progressBgColor}

                                        />

                                        <Text
                                            fontSize={'sm'}
                                            color={'gray.500'}
                                        >
                                            Resets on {moment(accountDetails.limitResetsOn).format('MMMM Do YYYY, [at] h:mmA')}
                                        </Text>
                                    </>
                                }

                                {accountDetails.limitReached &&
                                    <LimitReached />
                                }
                                <Divider borderColor={borderColor} />

                                <Button
                                    _active={{}}
                                    _focus={{}}
                                    _hover={{}}
                                    color={theme.default.defaultColor}
                                    bgColor={'transparent'}
                                    onClick={handleManageSubscription}
                                >
                                    Manage Subscription
                                </Button>

                                <Divider borderColor={borderColor} />

                                <Button
                                    _active={{}}
                                    _focus={{}}
                                    _hover={{}}
                                    color={theme.default.defaultColor}
                                    bgColor={'transparent'}
                                    onClick={() => handleLogout(true)}
                                >
                                    Logout
                                </Button>
                            </Stack>
                        }
                    </ModalBody>

                    {/* <ModalFooter >
                        <Button
                            _active={{}}
                            _focus={{}}
                            _hover={{}}
                            color={theme.default.defaultColor}
                            bgColor={'transparent'}
                            w={'full'}
                        >
                            Logout
                        </Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    )
}

export default MyAccountMobile