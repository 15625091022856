import theme from '../../../Config/theme'

import { Button } from '@chakra-ui/react'

const PrimaryButton = ({ children, size, onClick, rightIcon, ...rest }) => {
    return (
        <Button
            _hover={{}}
            _focus={{}}
            _active={{}}
            w={'full'}
            size={size || 'md'}
            bgColor={theme.default.defaultColor}
            borderRadius={'full'}
            color={'white'}
            onClick={onClick}
            rightIcon={rightIcon || null}
            {...rest}
        >
            {children}
        </Button>
    )
}

export default PrimaryButton