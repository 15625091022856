import { Link } from '@remix-run/react'
import {
    Box,
    Flex,
    Button,
    useDisclosure,
    Stack,
    useColorMode,
    Heading,
    useColorModeValue,
    Text,
    StackDivider,
    useMediaQuery,
} from '@chakra-ui/react'

import theme from '../../Config/theme'
import config from '../../Config/config'

import { IoSunny, IoMoon, IoMenuOutline, IoClose } from "react-icons/io5";

import PrimaryButton from '../Elements/PrimaryButton'
import TextButton from '../Elements/TextButton'
import NavigationBarItem from './NavigationBarItem'
import AuthButtons from './AuthButtons'
import MyAccountDesktop from './MyAccount/MyAccountDesktop'
import MyAccountMobile from './MyAccount/MyAccountMobile'
import MobileSidebar from './MobileSidebar'

import CookiesMessage from './CookiesMessage'

import useIsAuthenticated from '../../CustomHooks/useIsAuthenticated'

const NavigationBar = () => {
    const isAuthenticated = useIsAuthenticated()
    const { colorMode, toggleColorMode } = useColorMode()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const isDesktop = useMediaQuery("(min-width: 768px)")

    const backgroundColor = useColorModeValue('gray.50', 'gray.900')
    const backgroundColorButton = useColorModeValue(theme.iconButton.primaryLight, theme.iconButton.primaryDark)
    const borderColor = useColorModeValue(theme.borderColor.borderColorLight, theme.borderColor.borderColorDark)

    return (
        <>
            <CookiesMessage />

            <Box px={4} bgColor={backgroundColor} borderBottom={'1px'} borderColor={borderColor}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <Button
                        display={{ base: 'hidden', lg: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                        size={'lg'}
                        style={{ boxShadow: 'none' }}
                        bgColor={backgroundColorButton}
                        rounded={'full'}
                        p={0}
                    >
                        {isOpen ? <IoClose /> : <IoMenuOutline />}
                    </Button>

                    <Stack direction={'row'} alignItems={'center'} divider={isDesktop[0] && <StackDivider borderColor={borderColor} display={{ base: 'none', md: 'none', lg: 'flex' }} />} spacing={5}>
                        <Link to='/'>
                            <Heading fontSize='2xl' color={theme.default.defaultColor}>daddysteach</Heading>
                        </Link>
                        <Stack
                            as={'nav'}
                            spacing={5}
                            display={{ base: 'none', md: 'none', lg: 'flex' }}
                            direction={'row'}
                        >
                            <NavigationBarItem to={'/'} label={'Homework Q&A'} />
                            <NavigationBarItem to={'/scholarships'} label={'Scholarships'} />
                            <NavigationBarItem to={'/pricing'} label={'Plans'} />
                            {/* <NavbarItem to={'/scholarships'} label={'Scholarships'} badgeLabel={'NEW'} /> */}
                        </Stack>
                    </Stack>

                    <Flex alignItems={'center'}>
                        <Stack direction={'row'} spacing={5}>
                            {!isAuthenticated &&
                                <Stack
                                    direction={'row'}
                                    display={{ base: 'none', md: 'none', lg: 'flex' }}
                                    align={'center'}
                                >
                                    <AuthButtons />
                                </Stack>
                            }



                            <Button onClick={toggleColorMode} size={'md'} style={{ boxShadow: 'none' }} bgColor={backgroundColorButton} rounded={'full'} p={0}>
                                {colorMode === 'light' ? <IoMoon /> : <IoSunny />}
                            </Button>

                            {isAuthenticated &&
                                <Stack display={{ base: 'none', md: 'none', lg: 'flex' }}>
                                    <MyAccountDesktop />
                                </Stack>
                            }
                        </Stack>
                    </Flex>

                </Flex>
            </Box>

            <MobileSidebar isOpen={isOpen} onClose={onClose} />
        </>
    )
}

export default NavigationBar
